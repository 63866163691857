import React, { useState, useEffect, useRef } from 'react';
import styles from '../home/home.module.css';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import headerStyles from './header.module.css';
import { useNavigate } from 'react-router-dom';
import MenuBar from '../components/menu.tsx';
import { ReactComponent as BurgerMenu } from '../../assets/icons/menu-svgrepo-com.svg';
import { ReactComponent as PfpProfile } from '../../assets/icons/pfpProfile.svg'; 

const Header = ({ children }) => {
  const [user, setUser] = useRecoilState(userState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 570);

  const toggleMenu = () => {
    setMenuVisible(prevState => !prevState);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  }

  /**
   * resize function for profile dropdown and profile photo
   */
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 570);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setUser({});
    setIsDropdownOpen(false);
    navigate('/');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <div className={styles.header}>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        {isMenuVisible ? (
          <MenuBar hideMenu={hideMenu} />
        ) : (
          <BurgerMenu className={styles.smallBurgerMenu} />
        )}
      </div>
      <div className={styles.navOptions}>
        {children}
      </div>
      {(!user || Object.keys(user).length === 0) ? (
        <div className={headerStyles.signIn} onClick={handleSignIn}>LOG IN / SIGNUP</div>
      ) : (
        <>
          {isMobileView ? (
            <div className={` ${headerStyles.dropdown} ${headerStyles.userName}`}>
              {user.bbdUser.user.profile_photo !== 'undefined' && user.bbdUser.user.profile_photo !== '' && user.bbdUser.user.profile_photo !== 'empty' ? (
                <img
                  src={user.bbdUser.user.profile_photo}
                  alt="Profile"
                  className={headerStyles.profilePhoto}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)} 
                />
              ) : (
                <div className="relative w-[50px] h-[50px] flex-shrink-0 bg-gradient-to-b from-[#DEEFFF] to-[#BCDFFF] rounded-full flex items-center justify-center mx-auto mb-3">
                  <PfpProfile onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
                </div>
              )}
              <div className={`${headerStyles.dropdown_content} ${isDropdownOpen ? `${headerStyles.show}` : ''}`}>
                <a onClick={handleLogout}>Log Out</a>
              </div>
            </div>
          ) : (
            <div className={` ${headerStyles.dropdown} ${headerStyles.userName}`}>
              <div className={headerStyles.path} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {user.bbdUser.user.firstName} {user.bbdUser.user.lastName}
              </div>
              <div className={`${headerStyles.dropdown_content} ${isDropdownOpen ? `${headerStyles.show}` : ''}`}>
                <a onClick={handleLogout}>Log Out</a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Header;

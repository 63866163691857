import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './login.module.css';
import Register2 from '../../registration/register2.tsx';

export default function InitialScreen() {
  const [isLogin, setIsLogin] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState('signIn');

  const handleSignInActive = () => {
    setActiveOption('none');
    setTimeout(() => {
      setIsLogin(true);
      setActiveOption('signIn');
      setShowForm(false);
    });
  };

  const handleSignUpActive = () => {
    setActiveOption('none');
    setTimeout(() => {
      setIsLogin(false);
      setActiveOption('signUp');
      setShowForm(false);
    });
  };

  const handleUserType = (type) => {
    console.log(`Selected user type: ${type}`);
    if (type === '1' || type === '2') {
      setUserType(type);
      setShowForm(true);
    } else if (type === 'guest') {
      navigate('/');
    }
  };

  return (
    <div className={`w-screen min-h-screen overflow-y-scroll overflow-x-hidden flex flex-col items-center relative ${styles.loginBody}`}>
      <div id="container" className={`${styles.container} my-auto ${activeOption === 'signUp' ? styles.rightPanelActive : ''}`}>
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <div className={`${styles.overlayPanel} ${styles.overlayLeft} ${activeOption === 'signUp' ? styles.overlayLeftActive : ''}`}>
              <img
                className="object-cover"
                src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                alt="WORXINN"
              />
              <h1>Welcome Back!</h1>
              <p>To keep connected with us please login with your personal info</p>
              <button className={`${styles.ghost} animate-pulse`} onClick={handleSignInActive}>Login</button>
            </div>
            <div className={`${styles.overlayPanel} ${styles.overlayRight} ${activeOption === 'signUp' ? styles.overlayRightActive : ''}`}>
              <img
                className="object-cover"
                src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                alt="WORXINN"
              />
              <h1>Hello, Friend!</h1>
              <p>Enter your personal details and start your journey with us</p>
              <button className={`${styles.ghost} animate-pulse`} onClick={handleSignUpActive}>Register</button>
            </div>
          </div>
        </div>
        {activeOption === 'signUp' && (
          <div className={`${styles.formContainer} ${styles.signUpContainer} fade-in-2`}>
            <div className='flex flex-col justify-center items-center h-full gap-2'>
              {!showForm && (
                <>
                  <button className={styles.buttonOptions} onClick={() => handleUserType('2')}> As a venue </button>
                  <button className={styles.button87} onClick={() => handleUserType('1')}> As a user </button>
                </>
              )}
              {showForm && <Register2 isLogin={isLogin} userType={userType} />}
            </div>
          </div>
        )}
        {activeOption === 'signIn' && (
          <div className={`${styles.formContainer} ${styles.signInContainer} fade-in`}>
            <div className='flex flex-col justify-center items-center h-full gap-2'>
              {!showForm && (
                <>
                  <button className={styles.buttonOptions} onClick={() => handleUserType('2')}> As a venue </button>
                  <button className={styles.button87} onClick={() => handleUserType('1')}> As a user </button>
                  <button className={styles.button60} onClick={() => handleUserType('guest')}> Go back as a guest </button>
                </>
              )}
              {showForm && <Register2 isLogin={isLogin} userType={userType} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
